import cloneDeep from 'lodash/cloneDeep'

const SHOP_ALL_KEYWORD = process.env.VUE_APP_SHOP_ALL_KEYWORD
const CACHE_DURATION = 180000 // 3 minutes
const _cachedResults = {}

export function routeToData(route) {
	let { filters } = route.params
	if (typeof filters == 'string') {
		filters = filters.split('/').filter((str) => !!str.trim())
	}
	if (!filters.length) return false

	let data = { filters: [] }
	let subFilters = [
		{
			re: /^brand-(.+)$/,
			onMatch: (m) => {
				data.brand = m[1]
			},
		},
		{
			re: /^from-([0-9]+)(-to-([0-9]+))?$/,
			onMatch: (m) => {
				data.fromPrice = m[1]
				data.toPrice = m[3]
			},
		},
		{
			re: /^sort-by-(.+)$/,
			onMatch: (m) => {
				data.sortBy = m[1]
			},
		},
		{
			re: /^(.+)--(.+)$/,
			repeat: true,
			onMatch: (m) => {
				data.attrs = data.attrs || {}
				data.attrs[m[1]] = m[2]
			},
		},
	]

	let subFilterIndex = -1
	for (let filter of filters) {
		filter = String(filter)

		let subFilter = subFilters.find((subFilter, i) => {
			if (i < subFilterIndex) return false
			let m = filter.match(subFilter.re)
			if (!m) return false
			subFilter.onMatch(m)
			subFilterIndex = subFilter.repeat ? i : i + 1
			return true
		})

		if (subFilterIndex == -1) {
			data.filters.push(filter)
		} else if (!subFilter || !data.filters.length) {
			return false
		}
	}

	let { search } = route.query
	if (search) data.search = search

	return data
}

export function dataToRoute(data = {}) {
	let filters = [...(data.filters || [SHOP_ALL_KEYWORD])]

	if (data.brand) {
		filters.push(`brand-${data.brand}`)
	}
	let { fromPrice, toPrice } = data
	if (isNaN(fromPrice) || fromPrice < 0) fromPrice = 0
	if (isNaN(toPrice) || toPrice <= 0 || (fromPrice && toPrice < fromPrice)) {
		toPrice = null
	}
	if (fromPrice || toPrice) {
		let filterStrs = []
		filterStrs.push(`from-${parseInt(fromPrice)}`)
		if (toPrice) filterStrs.push(`to-${parseInt(toPrice)}`)
		filters.push(filterStrs.join('-'))
	}
	if (data.sortBy) {
		filters.push(`sort-by-${data.sortBy}`)
	}
	if (data.attrs) {
		Object.entries(data.attrs)
			.sort((a, b) => (a[0] > b[0] ? 1 : -1))
			.forEach((attr) => {
				filters.push(`${attr[0]}--${attr[1]}`)
			})
	}

	let route = { name: 'shop', params: { filters, scrollPosition: 'reset' } }
	let { search } = data
	if (search) route.query = { search }
	return route
}

export function toggleShopAllKeyword(filters, b) {
	if (b && !filters.length) {
		filters.push(SHOP_ALL_KEYWORD)
	} else if (!b && filters[0] == SHOP_ALL_KEYWORD) {
		filters.splice(0, 1)
	}
}

export function getFilterRoute(currentRoute, type, value, added, shop) {
	let data = routeToData(currentRoute)
	let { filters } = data

	if (type == 'brand') {
		data.brand = added ? value : null
	} else if (type == 'collection') {
		let appliedCollectionsLength = shop.appliedFilters.filter(
			(filter) => filter.type == 'collection'
		).length
		if (added) {
			toggleShopAllKeyword(filters, false)
			let collections = filters.slice(0, appliedCollectionsLength)
			let categories = filters.slice(appliedCollectionsLength)
			filters = [...collections, value, ...categories]
		} else {
			let collections = filters.slice(0, appliedCollectionsLength)
			let categories = filters.slice(appliedCollectionsLength)
			collections.splice(collections.indexOf(value), 1)
			filters = collections.concat(categories)
			toggleShopAllKeyword(filters, true)
		}
	} else if (type == 'category') {
		if (added) {
			toggleShopAllKeyword(filters, false)
			filters.push(value)
		} else {
			filters.pop()
			toggleShopAllKeyword(filters, true)
		}
	} else if (type == 'sortBy') {
		data.sortBy = added ? value : null
	} else if (type == 'priceRange') {
		if (added) {
			let [fromPrice, toPrice] = value
			data.fromPrice = fromPrice
			data.toPrice = toPrice
		} else {
			data.fromPrice = data.toPrice = null
		}
	} else if (type == 'attr') {
		data.attrs = data.attrs || {}
		if (added) data.attrs[value[0]] = value[1]
		else delete data.attrs[value[0]]
	} else if (type == 'search') {
		if (!added) data.search = null
	}
	return dataToRoute({ ...data, filters })
}

export function getBreadcrumbRoute(currentRoute, urlNames) {
	let data = routeToData(currentRoute)
	data.filters = urlNames
	let route = dataToRoute(data)
	route.params.scrollPosition = 'keep'
	return route
}

function _cacheKeyData(route) {
	let { filters } = route.params
	let { search, page } = route.query
	if (!Array.isArray(filters)) {
		filters = filters.split('/').filter(Boolean)
	}
	search = search || null
	page = Number(page) || 1
	return { filters, search, page }
}

export function setCache(route, shop) {
	let key = JSON.stringify(_cacheKeyData(route))
	_cachedResults[key] = { shop: cloneDeep(shop), e: Date.now() + CACHE_DURATION }
}

export function getCache(route) {
	let key = JSON.stringify(_cacheKeyData(route))
	const cached = _cachedResults[key]
	if (!cached) return null
	if (Date.now() > cached.e) {
		_cachedResults[key] = undefined
		return null
	} else {
		return cached.shop
	}
}

export function getAllCachedProducts(route, untilPage) {
	let _route = {
		params: { ...route.params },
		query: { ...route.query },
	}
	let products = []
	for (let page = 1; page <= untilPage; page++) {
		_route.query.page = page
		let shop = getCache(_route)
		if (!shop?.products?.length) return []
		products = products.concat(shop.products)
	}
	return products
}
